export const isDev =
  (process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV) === 'development';

// process.env.CONTEXT is set by Netlify when hosting there
export const isProduction =
  !isDev &&
  (typeof process.env.CONTEXT === 'undefined' ||
    process.env.CONTEXT === 'production');

export const isSSG = typeof window === 'undefined';

export const isNetlify = isSSG
  ? process.env.NETLIFY === 'true'
  : window.location.hostname.includes('netlify.app');

export enum LocalStorageKey {}
// e.g.: acceptedEULA = 'com.nerlynx.experience.accepted_eula',
