import { useEffect, useState } from 'react';
import { isSSG } from '~/config';

/**
 * Use this when you want to defer render until window size is
 * known in JS. This erases benefits of SSG but avoids flashing.
 *
 * (For true multi-device pre-render, fresnel is clunky but works.)
 */
export function useHasMounted() {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => setHasMounted(!isSSG), []);

  return hasMounted;
}
