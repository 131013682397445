import React, {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useState,
} from 'react';

export type TimeData = {
  delta: number;
  elapsed: number;
  count: number;
};

export const RAF_EVENT_NAME = 'RAF';

export const RAFContext = createContext<{
  enabled: boolean;
  setEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  enabled: true,
  setEnabled: () => {},
});

type RAFContextProviderProps = {
  enabled: boolean;
  children?: ReactNode;
};

export const RAFContextProvider: FC<RAFContextProviderProps> = ({
  enabled = true,
  children,
}) => {
  const [_enabled, setEnabled] = useState(enabled);
  useEffect(() => {
    // Don't register for node environments (e.g. Server-Side Rendering)
    if (typeof window === 'undefined' || !_enabled) {
      return;
    }

    let raf: number;
    let time: TimeData = {
      delta: 1000 / 60,
      elapsed: performance.now(),
      count: 0,
    };

    const handleTick = () => {
      const elapsed = performance.now();
      const delta = elapsed - time.elapsed;
      const c = (time.count + 1) % 4;
      time = { delta, elapsed, count: c };

      const event = new CustomEvent<TimeData>(RAF_EVENT_NAME, { detail: time });

      window.dispatchEvent(event);

      raf = window.requestAnimationFrame(handleTick);
    };

    raf = window.requestAnimationFrame(handleTick);

    return () => {
      window.cancelAnimationFrame(raf);
    };
  }, [_enabled]);

  return (
    <RAFContext.Provider
      value={{
        enabled: _enabled,
        setEnabled,
      }}
    >
      {children}
    </RAFContext.Provider>
  );
};
