import { useEffect, useState } from 'react';
import { isSSG } from '~/config';

/**
 * Workaround for outlines that flash or stick on click.
 *
 * In general, never globally disable the `outline` attribute for
 * accessibility (a11y) reasons, but this can cause visual glitches
 * when using a mouse.
 *
 * This hook provides two ways to automate hiding an element's outline
 * when the mouse is active (and re-enabling it when tab is pressed): a
 * flag for use w/classnames, and an always-defined style object.
 *
 * @param startHidden Normally not needed, useful for js focus cases.
 * @returns A destructurable object with a `hideOutline` boolean flag
 *          and a spreadable `hideOutlineStyle` object for convenience
 */
export const useTabOutline = (startHidden = false) => {
  const [hideOutline, setHideOutline] = useState(startHidden);

  useEffect(() => {
    if (isSSG) {
      return;
    }

    // Move is used to avoid flash on initial click.
    // Outine is restored when tab is pressed.
    const handleMoused = () => {
      setHideOutline(true);
      window.removeEventListener('mousemove', handleMoused);
      window.addEventListener('keyup', handleKeyed);
    };
    const handleKeyed = (e) => {
      if (e.keyCode === 9) {
        setHideOutline(false);
        window.removeEventListener('keyup', handleKeyed);
        window.addEventListener('mousemove', handleMoused);
      }
    };

    window.addEventListener('mousemove', handleMoused);

    return () => {
      window.removeEventListener('mousemove', handleMoused);
      window.removeEventListener('keyup', handleKeyed);
    };
  }, []);

  return {
    hideOutline,
    hideOutlineStyle: hideOutline ? { outline: 'none' } : {},
  };
};
