import { useEffect, useState } from 'react';
import { useHasMounted } from './useHasMounted';

/**
 * A custom hook which wraps window.matchMedia registration
 *
 * @param mediaQuery Media query used to determine whether to call handler
 * @param handler Called when media query match state changes
 * @param deps Triggers handler when dependencies change
 * @returns { isMediaQueryOnline: boolean } indicating client size is known
 */
export function useMedia(
  mediaQuery: string,
  handler: (match: boolean) => void,
  deps = [],
) {
  const hasMounted = useHasMounted();
  const [isOnline, setIsOnline] = useState(false);

  useEffect(() => {
    // Don't run in node environment (i.e. Server-side prerendering)
    if (!hasMounted) {
      return;
    }

    const mediaQueryList = window.matchMedia(mediaQuery);

    const propagate = (e: MediaQueryListEvent) => {
      setIsOnline(true);
      return e.matches ? handler(true) : handler(false);
    };

    // Subscribe
    // Older versions of mobile safari use the deprecated api
    mediaQueryList.hasOwnProperty('addEventListener')
      ? mediaQueryList.addEventListener('change', propagate)
      : mediaQueryList.addListener(propagate);

    // Fire an initial invocation
    mediaQueryList.dispatchEvent(
      new MediaQueryListEvent('change', {
        matches: mediaQueryList.matches,
        media: mediaQuery,
      }),
    );

    // Cleanup
    return () =>
      mediaQueryList.hasOwnProperty('removeEventListener')
        ? mediaQueryList.removeEventListener('change', propagate)
        : mediaQueryList.removeListener(propagate);
  }, [
    hasMounted,
    handler,
    mediaQuery,
    // eslint-disable-next-line
    ...deps,
  ]);

  return { isMediaQueryOnline: isOnline };
}
