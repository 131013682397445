import { useEffect, useState } from 'react';
import { isSSG } from '~/config';

/**
 * Hook that provides a state that's similar to the one used by `focus-visible` polyfill.
 *
 * @param disable turn off this hook
 * @returns Returned state is false initially, then true once tab has been pressed, until mouse move is detected.
 */
export const useIsTabbing = (disable = false) => {
  const [isTabbing, setIsTabbing] = useState(false);

  useEffect(() => {
    if (isSSG) {
      return;
    }

    const cleanup = () => {
      window.removeEventListener('mousemove', handleMoused);
      window.removeEventListener('keyup', handleKeyed);
    };

    const handleMoused = () => {
      setIsTabbing(false);
      window.removeEventListener('mousemove', handleMoused);
      if (!disable) {
        window.addEventListener('keyup', handleKeyed);
      }
    };
    const handleKeyed = (e) => {
      if (e.keyCode === 9) {
        setIsTabbing(true);
        window.removeEventListener('keyup', handleKeyed);
        if (!disable) {
          window.addEventListener('mousemove', handleMoused);
        }
      }
    };

    if (!disable) {
      window.addEventListener('keyup', handleKeyed);
    } else {
      cleanup();
    }

    return cleanup;
  }, [disable]);

  return isTabbing;
};
