import { useCallback, useEffect, useState } from 'react';
import { isSSG } from '~/config';

// TODO: keep an eye on https://github.com/microsoft/TypeScript/issues/38886
// in case they unlock KeyboardEvent['key'] as a hinted type.
// options: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
type KeyboardKey = string;

/**
 * Simple hook that provides a boolean key-is-down state for a specific key
 *
 * @param targetKey a KeyboardEvent key string like 'Escape'
 * @param handler optional keydown handler (no need to wrap in useCallback)
 * @returns `true` when the key is down
 */
export const useKeyPress = (
  targetKey: KeyboardKey | string,
  handler?: () => void,
): boolean => {
  const [isDown, setIsDown] = useState(false);
  const handlerMemo = useCallback(() => handler?.(), [handler]);

  const handleDown = useCallback(
    ({ key }) => {
      if (key === targetKey) {
        setIsDown(true);
        handlerMemo();
      }
    },
    [targetKey, handlerMemo],
  );

  const handleUp = useCallback(
    ({ key }) => key === targetKey && setIsDown(false),
    [targetKey],
  );

  useEffect(() => {
    if (!isSSG) {
      window.addEventListener('keydown', handleDown);
      window.addEventListener('keyup', handleUp);
    }

    return () => {
      if (!isSSG) {
        window.removeEventListener('keydown', handleDown);
        window.removeEventListener('keyup', handleUp);
      }
    };
  }, [handleDown, handleUp]);

  return isDown;
};
