import React, {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { useIsTabbing } from '~/hooks';

export const TabbingContext = createContext<{
  isTabbing: boolean;
  hasTabbed: boolean;
}>({
  isTabbing: false,
  hasTabbed: false,
});

/**
 * Context that provides `isTabbing` and `hasTabbed` global states.
 *
 * Like the `useIsTabbing` hook this is based on, `isTabbing` turns true when
 * the tab key is pressed and resets on mouse move, whereas `hasTabbed` does not.
 */
export const TabbingContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const isTabbing = useIsTabbing();
  const [hasTabbed, setHasTabbed] = useState(false);

  useEffect(() => {
    if (isTabbing) {
      setHasTabbed(true);
    }
  }, [isTabbing]);

  return (
    <TabbingContext.Provider value={{ isTabbing, hasTabbed }}>
      {children}
    </TabbingContext.Provider>
  );
};
