import { useEffect, useRef, useState } from 'react';
import { isSSG } from '~/config';

export type ViewportData = {
  viewportWidth: number;
  viewportHeight: number;
  scrollHeight: number;
};

/**
 * A custom hook which wraps window resize event registration.
 * This improves ergonomics and ensures cleanup is always handled appropriately.
 *
 * Returns bindable references to the viewport dimensions and accepts an optional
 * handler invoked on window resize.
 *
 * @param handler Called on every resize event, as well as once immediately
 * @param deps    List of dependencies used within the handler
 * @returns { viewportWidth: number, viewportHeight: number }
 */
export function useResize(handler?: (data: ViewportData) => void, deps = []) {
  const data = useRef<ViewportData>({
    viewportWidth: 0,
    viewportHeight: 0,
    scrollHeight: 0,
  });
  const [tick, setTick] = useState(0);

  useEffect(() => {
    if (isSSG) {
      return;
    }

    const handleResize = () => {
      const newData = {
        viewportWidth: window.innerWidth,
        viewportHeight: window.innerHeight,
        scrollHeight:
          parseFloat((document.body.style.height || '0').replace('px', '')) ||
          document.body.clientHeight, // fallback for mobile, which doesn't get lerp-resize events
      };
      if (
        data.current.viewportWidth !== newData.viewportWidth ||
        data.current.viewportHeight !== newData.viewportHeight ||
        data.current.scrollHeight !== newData.scrollHeight
      ) {
        data.current = newData;
        setTick(tick + 0.1);
      }

      if (handler) {
        handler({ ...data.current });
      }
    };

    window.addEventListener('resize', handleResize, false);
    window.addEventListener('lerp-resize', handleResize, false);

    // Fire initial invocation
    handleResize();
    // Invoke again after a delay, for mobile which doesn't get lerp-resize events
    let timer = setTimeout(handleResize, 250);

    return () => {
      clearTimeout(timer);
      window.removeEventListener('resize', handleResize, false);
      window.removeEventListener('lerp-resize', handleResize, false);
    };
  }, [
    tick,
    handler,
    // eslint-disable-next-line
    ...deps,
  ]);

  return { ...data.current };
}
